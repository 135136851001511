// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["OVry4XNYF", "EjRzoYgqb", "vn0asGgXc"];

const variantClassNames = {EjRzoYgqb: "framer-v-1o8btuz", OVry4XNYF: "framer-v-4dm8z7", vn0asGgXc: "framer-v-cuz42b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "OVry4XNYF", "Variant 2": "EjRzoYgqb", "Variant 3": "vn0asGgXc"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, gbgFrWZSl: title ?? props.gbgFrWZSl ?? "Portfólio", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "OVry4XNYF"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gbgFrWZSl, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "OVry4XNYF", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1p4mxzk = activeVariantCallback(async (...args) => {
setVariant("EjRzoYgqb")
})

const onTap1pvd1t6 = activeVariantCallback(async (...args) => {
setVariant("vn0asGgXc")
})

const onMouseLeave1pvd1t6 = activeVariantCallback(async (...args) => {
setVariant("vn0asGgXc")
})

const onMouseLeave1p4mxzk = activeVariantCallback(async (...args) => {
setVariant("EjRzoYgqb")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AFScb", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({EjRzoYgqb: {href: {webPageId: "NU0vuFcN7"}}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-4dm8z7", className)} framer-t30oln`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"OVry4XNYF"} onMouseEnter={onMouseEnter1p4mxzk} ref={ref} style={{...style}} {...addPropertyOverrides({EjRzoYgqb: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave1pvd1t6, onTap: onTap1pvd1t6}, vn0asGgXc: {"data-framer-name": "Variant 3", onMouseLeave: onMouseLeave1p4mxzk}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Portfólio</motion.p></React.Fragment>} className={"framer-1jcdyj5"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"Y1vp9btvf"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px", textShadow: "none"}} text={gbgFrWZSl} transformTemplate={transformTemplate} variants={{EjRzoYgqb: {textShadow: "0px 0px 7px hsl(0, 0%, 100%)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AFScb [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AFScb .framer-t30oln { display: block; }", ".framer-AFScb .framer-4dm8z7 { height: 24px; overflow: visible; position: relative; width: 65px; }", ".framer-AFScb .framer-1jcdyj5 { flex: none; height: auto; left: 49%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-AFScb.framer-v-1o8btuz .framer-4dm8z7 { cursor: pointer; text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 65
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"EjRzoYgqb":{"layout":["fixed","fixed"]},"vn0asGgXc":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gbgFrWZSl":"title"}
 * @framerImmutableVariables false
 */
const FramerwqMt4Kdgv: React.ComponentType<Props> = withCSS(Component, css, "framer-AFScb") as typeof Component;
export default FramerwqMt4Kdgv;

FramerwqMt4Kdgv.displayName = "portfolio c";

FramerwqMt4Kdgv.defaultProps = {height: 24, width: 65};

addPropertyControls(FramerwqMt4Kdgv, {variant: {options: ["OVry4XNYF", "EjRzoYgqb", "vn0asGgXc"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, gbgFrWZSl: {defaultValue: "Portfólio", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerwqMt4Kdgv, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/wqMt4Kdgv:default", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf", weight: "500"}])